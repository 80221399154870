import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../components/layout";
import { DataProvider, componentMap, getLocaleUrl } from "../../contentful/data-provider";

import loopVideo from "../../images/0330-glassview-06_UltraSmall.mp4";
import loopVideoMobile from "../../images/0331-glassview-08-vertical-mute-.mp4";
import loopVideoPoster from "../../images/poster.png";
import loopVideoMobileoPoster from "../../images/mobile-poster.png";

import ScrollDown from "../../components/elements/scroll-down";
import BtnPlay from "../../components/elements/btn-play";
import Seo from "../../components/seo";
import VideoWithPopUpSection from "../../components/sections/neuro-powered-marketing";

const IndexPage = ({ data }) => {
	const dataProvider = new DataProvider(data);
	const title = data.allContentfulPage.edges[0].node.title;

	return (
		<div className={"homepage"}>
			<Layout dataProvider={dataProvider}>
				<Seo dataProvider={dataProvider} title={`Glassview | ${title}`} />
				<main>
					{dataProvider.getCurrentPageSections().map((section, index) => {
						return (
							<>
								{index === 0 && (
									<section className={"hero-work flex items-center"} key={0}>
										<div className={"bg overflow-hidden"}>
											<div className={"overlay"} />
											<video src={loopVideo} muted playsInline loop={true} autoPlay={true} className={"hidden md:block"} />
											<video src={loopVideoMobile} muted playsInline loop={true} autoPlay={true} className={"md:hidden"} />
										</div>
										<div className={"container h-full flex xl:block items-end"}>
											<div className={"flex flex-wrap items-end xl:h-full justify-between"}>
												<div className={"basis-full md:basis-auto"}>
													<div className={"hero-subtitle"}>&nbsp;</div>
													<h1 className={"h1 text-white"}>
														The Why <br />
														Behind the Buy
													</h1>
												</div>
											</div>
										</div>
										<ScrollDown />
									</section>
								)}
							</>
						);
					})}
					{dataProvider.getCurrentPageSections().map((section, index) => {
						return (
							<>
								{index == 1 && (
									<>
										{React.createElement(componentMap[section.type], {
											key: index,
											dataProvider: dataProvider,
											content: dataProvider.getElementById(section.id, section.locale),
										})}
									</>
								)}
							</>
						);
					})}
					<section className={"pt-14 md:pt-[100px] pb-4"}>
						<div className='container'>
							<VideoWithPopUpSection title='Neuro-powered Marketing' videoID={"ND4EhflKxUA"} />
						</div>
					</section>
					{dataProvider.getCurrentPageSections().map((section, index) => {
						return (
							<>
								{index !== 0 && index !== 1 && (
									<>
										{React.createElement(componentMap[section.type], {
											key: index,
											dataProvider: dataProvider,
											content: dataProvider.getElementById(section.id, section.locale),
										})}
									</>
								)}
							</>
						);
					})}
				</main>
			</Layout>
		</div>
	);
};

export default IndexPage;

export const query = graphql`
	query ($locale: String!, $contentfulId: String!) {
		allContentfulPage(filter: { contentful_id: { eq: $contentfulId }, node_locale: { eq: $locale } }) {
			edges {
				node {
					...CurrentPageDetails
				}
			}
		}
		allContentfulEntry {
			edges {
				node {
					...AllEntryDetails
				}
			}
		}
		allContentfulMenu(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					...Menus
				}
			}
		}
	}
`;
